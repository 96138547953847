<template>
  <div>
    <app-timeline class="m-auto">
      <app-timeline
        class="m-auto"
        v-for="item in delivery_send_list"
        :key="item.d_id"
      >
        <div v-if="item.task_item != null">
          <app-timeline-item
            variant="danger"
            v-if="item.task_item.status == 'pending'"
          >
            <div
              class="
                d-flex
                flex-sm-row flex-column flex-wrap
                justify-content-between
                mb-1 mb-sm-0
              "
            >
              <h3 class="mtn1 text-white">Gönderim Bekliyor</h3>
            </div>
            <div v-if="item.task_item.staff_item != null" class="mt-2 mb-3">
              <div>
                Değerli müşterimiz cihazınız
                <b>{{ item.task_item.staff_item.user_namesurname }}</b>
                tarafından
                <b>{{
                  moment(item.task_item.planned_date).format("DD.MM.YYYY HH:mm")
                }}</b>
                tarihinde teslim edilmek üzere kaydınız oluşturulmuştur.
              </div>
              <b-media class="mt-2 cs-media">
                <template #aside>
                  <b-avatar
                    class="avatarcontainer"
                    size="10rem"
                    :src="item.task_item.staff_item.profile_url"
                  />
                </template>
                <h5 class="m-0 text-white">
                  {{ item.task_item.staff_item.user_namesurname }}
                </h5>
                <div>Operasyon Personeli</div>
              </b-media>
            </div>
          </app-timeline-item>

          <app-timeline-item
            variant="warning"
            v-if="item.task_item.status == 'processing'"
          >
            <div
              class="
                d-flex
                flex-sm-row flex-column flex-wrap
                justify-content-between
                mb-1 mb-sm-0
              "
            >
              <h3 class="mtn1 text-white">Gönderim Aşamasında</h3>
            </div>
            <div v-if="item.task_item.staff_item != null" class="mt-2 mb-3">
              <div>
                Değerli müşterimiz cihazınız
                <b>{{ item.task_item.staff_item.user_namesurname }}</b>
                tarafından planlanan
                <b>{{
                  moment(item.task_item.planned_date).format("DD.MM.YYYY HH:mm")
                }}</b>
                tarihinde teslim edilmek üzere yola çıkmıştır.
              </div>
              <b-media class="mt-2 cs-media">
                <template #aside>
                  <b-avatar
                    class="avatarcontainer"
                    size="10rem"
                    :src="item.task_item.staff_item.profile_url"
                  />
                </template>
                <h5 class="m-0 text-white">
                  {{ item.task_item.staff_item.user_namesurname }}
                </h5>
                <div>Operasyon Personeli</div>
              </b-media>
            </div>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            v-if="item.task_item.status == 'complated'"
          >
            <div
              class="
                d-flex
                flex-sm-row flex-column flex-wrap
                justify-content-between
                mb-1 mb-sm-0
              "
            >
              <h5 class="text-uppercase">Cihazınız Teslim Edildi</h5>
            </div>
            <div v-if="item.task_item.staff_item != null" class="mt-2 mb-3">
              <div>
                Değerli müşterimiz cihazınız
                <b>{{ item.task_item.staff_item.user_namesurname }}</b>
                tarafından
                <b>{{
                  moment(item.task_item.complated_date).format(
                    "DD.MM.YYYY HH:mm"
                  )
                }}</b>
                tarihinde teslim edilmiştir.
              </div>
              <b-media class="mt-2 cs-media">
                <template #aside>
                  <b-avatar
                    class="avatarcontainer"
                    size="10rem"
                    :src="item.task_item.staff_item.profile_url"
                  />
                </template>
                <h5 class="m-0 text-white">
                  {{ item.task_item.staff_item.user_namesurname }}
                </h5>
                <div>Operasyon Personeli</div>
              </b-media>
            </div>
          </app-timeline-item>
        </div>
      </app-timeline>
    </app-timeline>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import StatusBadge from "./StatusBadge.vue";
import moment from "moment";
import {
  BAvatar,
  BMedia
} from "bootstrap-vue";
export default {
  components: {
    StatusBadge,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar
  },
  data() {
    return {
      copy_text: "",
    };
  },
  methods: {
    copy(v) {
      this.copy_text = v;
      let testingCodeToCopy = document.querySelector("#testing-code3");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
      } catch (err) {}
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    moment(v) {
      return moment(v);
    },
  },
  computed: {
    ...mapState({
      devicefind: (state) => state.devicefind,
    }),
    delivery_take_list() 
    {
      let result = [];
      result = this.devicefind.current_device.delivery_list.filter(
        (q) => q.type == "take"
      );
      return result;
    },
    delivery_send_list() {
      let result = [];
      result = this.devicefind.current_device.delivery_list.filter(
        (q) => q.type == "send"
      );
      return result;
    },
  },
};
</script>

<style lang="scss" scope>
ul {
  padding: 0px;
  list-style: none;
  li {
    list-style-type: none;
  }
}
</style>
<template>
<div>
      <div>
        <div class="lettitle text-uppercase">Uygulanacak İşlemler</div>
        <div>
          <div class="text-sm text-muted">Uzman teknik servis ekibimiz tarafından yapılan inceleme sonuçları aşağıdaki gibidir. </div>
          <table class="table table-gray mt-2">
            <tbody>
              <tr v-for="(item,index) in table_service_device_information_items" :key="index">
                <td>
                  <div>{{ item.notes }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import StatusBadge from "./StatusBadge.vue";
export default {

    computed: 
  {
    ...mapState({
      devicefind: (state) => state.devicefind,
    }),
    table_service_device_information_items() {
      let result = [];
      if (this.devicefind.current_device != null) 
      {
        result = this.devicefind.current_device.device_actions;
      }
      return result;
    }
  },

}
</script>
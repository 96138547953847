// @ts-nocheck

import moment from "moment";
import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

class Utils {

    constructor() { }

    static defaultSweetStyle =
        {
            popup: 'rounded-lg pr-5 pl-5',
            header: 'p-1',
            title: '...',
            actions: 'pt-2 pb-2',
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
        }


    static setTitle(title) {
        document.title = title;
    }


    static notificationToast(type = "success", title = "", text = "") {

        Vue.$toast({
            component: ToastificationContent,
            position: "bottom-center",
            theme: "toasted-primary",
            props: {
                title: title,
                icon: "AlertCircleIcon",
                variant: type,
                text: text,
                position: "bottom-center",
            },
        });

    }

}

export default Utils
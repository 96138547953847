<template>
  <div>


    <div>
      <div class="lettitle text-uppercase">Ödeme Tablosu</div>
      <div
        v-for="(item_payment, pindex) in devicefind.current_device.payments"
        :key="pindex"
      >
        <table class="table table-gray mt-2 mb-3">
          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-sm-3">Ödeme</div>
                  <div class="col-sm-9">{{ item_payment.category_name }}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="row">
                  <div class="col-sm-3">Ödeme No</div>
                  <div class="col-sm-9">{{ item_payment.p_id }}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="row">
                  <div class="col-sm-3">Açıklamalar</div>
                  <div class="col-sm-9">{{ item_payment.notes }}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="row">
                  <div class="col-sm-3">Ödeme Durumu</div>
                  <div class="col-sm-9">
                    <div>
                      <status-badge
                        :status="item_payment.status_item"
                      ></status-badge>
                    </div>
                    <div>
                      <div v-if="item_payment.status == 'pending'">
                        <a
                          class="badge bg-basered mt-1"
                          :href="item_payment.payment_link"
                          target="_blank"
                          >Ödeme Yap</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row align-items-center">
                  <div class="col-sm-3">Tutar</div>
                  <div class="col-sm-9">
                    <div class="h4 text-white mb-0">
                      {{ item_payment.amount }}
                      {{ item_payment.currency_name }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card usedark mt-5">
      <div class="card-body">
        <div class="letarea">
          <div class="lettitle">TKA Bilişim</div>
          <div class="lettitle borderredbig">Banka Hesaplarımız (TL)</div>
          <div class="row">
            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-garanti.svg" />
                <div class="bankname">TKA Garanti Bankası</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR690006200104700006292066</span>
                      <span
                        @click="copy('TR690006200104700006292066')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-yapikredi.svg" />
                <div class="bankname">TKA Yapı Kredi Bankası</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR240006701000000095815878</span>
                      <span
                        @click="copy('TR240006701000000095815878')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-is.svg" />
                <div class="bankname">TKA İş Bankası</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR490006400000112750676142</span>
                      <span
                        @click="copy('TR490006400000112750676142')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-qnb.svg" />
                <div class="bankname">TKA QNB Finansbank</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR980011100000000032740609</span>
                      <span
                        @click="copy('TR980011100000000032740609')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-enpara.svg" />
                <div class="bankname">TKA EnPara</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR360011100000000090056600</span>
                      <span
                        @click="copy('TR360011100000000090056600')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-kuveyt.svg" />
                <div class="bankname">TKA Kuveyt Türk</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR060020500009449807500001</span>
                      <span
                        @click="copy('TR060020500009449807500001')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-aktif.svg" />
                <div class="bankname">TKA Aktif Bank</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR160014300000000004742595</span>
                      <span
                        @click="copy('TR160014300000000004742595')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-teb.svg" />
                <div class="bankname">TKA TEB</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR810003200000000091495071</span>
                      <span
                        @click="copy('TR810003200000000091495071')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-akbank.svg" />
                <div class="bankname">TKA Akbank</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR720004601024888000127094</span>
                      <span
                        @click="copy('TR720004601024888000127094')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="card usedark mt-5">
      <div class="card-body">
        <div class="letarea">
          <div class="lettitle">TKA Bilişim</div>
          <div class="lettitle borderredbig">Döviz Hesaplarımız</div>
          <div class="row mt-2">
            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-akbank.svg" />
                <div class="bankname d-block">TKA (Dolar)</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR930004601024001000127095</span>
                      <span
                        @click="copy('TR930004601024001000127095')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-akbank.svg" />
                <div class="bankname d-block">TKA (Euro)</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR430004601024036000127096</span>
                      <span
                        @click="copy('TR430004601024036000127096')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-garanti.svg" />
                <div class="bankname d-block">TKA (Dolar)</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR430006200104700009082157</span>
                      <span
                        @click="copy('TR430006200104700009082157')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-garanti.svg" />
                <div class="bankname d-block">TKA (Euro)</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR270006200104700009085161</span>
                      <span
                        @click="copy('TR270006200104700009085161')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="bankitem">
                <img src="@/assets/images/bank-garanti.svg" />
                <div class="bankname d-block">TKA (Sterlin)</div>
                <div class="bankcontent">
                  <div class="row">
                    <div class="col-12 col-lg-3">IBAN No</div>
                    <div class="col-12 col-lg-9">
                      <span>TR790006200104700009083546</span>
                      <span
                        @click="copy('TR790006200104700009083546')"
                        class="text-basered ml-1 cursor-pointer"
                        ><feather-icon icon="CopyIcon" /> Kopyala</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">Hesap Adı</div>
                    <div class="col-12 col-lg-9">Tuğrul Kurşat Aksoy</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input type="hidden" id="testing-code3" :value="copy_text" />
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import StatusBadge from "./StatusBadge.vue";
export default {
  components: {
    StatusBadge,
  },
  data() {
    return {
      copy_text: "",
    };
  },
  methods: {
    copy(v) {
      this.copy_text = v;
      let testingCodeToCopy = document.querySelector("#testing-code3");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
      } catch (err) {}
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
  computed: {
    ...mapState({
      devicefind: (state) => state.devicefind,
    }),
  },
};
</script>
<template>
  <div class="container" v-if="devicefind.sf != null">
    <div class="stepssecond d-lg-none d-md-none d-block">
      <ul class="steps">
        <li @click="setActiveCard('1')" class="step" :class="{ 'complated':_steps[0].complated, 'active':_steps[0].current }">
          <div class="number_item">1</div>
          <div class="content_item">Cihaz Alımı</div>
        </li>
        <li @click="setActiveCard('2')" class="step" :class="{ 'complated':_steps[0].complated, 'active':_steps[0].current }">
          <div class="number_item">2</div>
          <div class="content_item">Yapılacak İşlemler</div>
        </li>
        <li @click="setActiveCard('3')" class="step" :class="{ 'complated':_steps[0].complated, 'active':_steps[0].current }">
          <div class="number_item">3</div>
          <div class="content_item">Ödeme</div>
        </li>
        <li @click="setActiveCard('4')" class="step" :class="{ 'complated':_steps[0].complated, 'active':_steps[0].current }">
          <div class="number_item">4</div>
          <div class="content_item">Test ve Garanti</div>
        </li>
        <li @click="setActiveCard('5')" class="step" :class="{ 'complated':_steps[0].complated, 'active':_steps[0].current }">
          <div class="number_item">5</div>
          <div class="content_item">Teslimat</div>
        </li>
        <li @click="setActiveCard('6')" class="step" :class="{ 'complated':_steps[0].complated, 'active':_steps[0].current }">
          <div class="number_item">6</div>
          <div class="content_item">Tamamlandı</div>
        </li>
      </ul>
    </div>

    <div class="row">
      <div class="col-sm-12 col-12 col-lg-5">
        <div class="card usedark">
          <div class="card-body" style="padding-bottom: 0.5rem">
            <div class="letarea">
              <div class="lettitle">Servis Takip Numarası</div>
              <div class="letcontent">
                <div class="d-flex">
                  <span class="font-weight-bold">{{
                    devicefind.sf.sf_no
                  }}</span>
                  <span
                    class="ml-2 btn-text text-basered"
                    @click="copy(devicefind.sf.sf_no)"
                  >
                    <feather-icon icon="CopyIcon" />
                    <span>KOPYALA</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="letarea">
              <div class="lettitle">Servis Bilgileri</div>
              <div class="letcontent">
                Sayın {{ _customer_name }}, TKA Premium'da bulunan cihazınızın
                bilgilerini ve servis durumunu kontrol edebilirsiniz.
              </div>
            </div>
            <div class="letarea">
              <div class="letcontent">
                <ul class="keyval">
                  <li>
                    <div class="k">Servis Tarihi:</div>
                    <div class="v">
                      {{ moment(devicefind.sf.sf_date).format("DD.MM.YYYY") }}
                    </div>
                  </li>
                  <li>
                    <div class="k">Servisteki Cihaz:</div>
                    <div class="v">{{ devicefind.devices.length }} Adet</div>
                  </li>
                  <li>
                    <div class="k">Durum:</div>
                    <div class="v">Açık</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card usedark">
          <div class="card-body" style="padding-bottom: 0.5rem">
            <div class="letarea">
              <div class="lettitle">Müşteri Kodu</div>
              <div class="letcontent">
                <div
                  class="d-flex"
                  @click="copy(devicefind.customer.customer_code)"
                >
                  <span class="font-weight-bold">{{
                    devicefind.customer.customer_code
                  }}</span>
                  <span class="ml-2 btn-text text-basered">
                    <feather-icon icon="CopyIcon" />
                    <span>KOPYALA</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="letarea">
              <div class="lettitle">Müşteri Bilgileri</div>
              <div class="letcontent">
                <ul class="keyval">
                  <li>
                    <div class="k">Ad Soyad:</div>
                    <div class="v">{{ _customer_name }}</div>
                  </li>
                  <li>
                    <div class="k">GSM No:</div>
                    <div class="v">{{ _customer_gsm }}</div>
                  </li>
                  <li>
                    <div class="k">Email:</div>
                    <div class="v">{{ _customer_email }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          col-sm-12 col-12 col-lg-7
          d-none d-sm-block d-md-block d-lg-block
        "
      >
        <div class="basecarousel">
          <b-carousel fade indicators controls height="200px">
            <b-carousel-slide class="carouselmy">
              <template v-slot:img>
                <img
                  src="@/assets/images/t1.png"
                  class="img-fluid rounded corouselhomemyimg"
                  style="height: auto !important; max-height: 475px"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide class="carouselmy">
              <template v-slot:img>
                <img
                  src="@/assets/images/t2.png"
                  class="img-fluid rounded corouselhomemyimg"
                  style="height: auto !important; max-height: 475px"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide class="carouselmy">
              <template v-slot:img>
                <img
                  src="@/assets/images/t3.png"
                  class="img-fluid rounded corouselhomemyimg"
                  style="height: auto !important; max-height: 475px"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide class="carouselmy">
              <template v-slot:img>
                <img
                  src="@/assets/images/t4.png"
                  class="img-fluid rounded corouselhomemyimg"
                  style="height: auto !important; max-height: 475px"
                />
              </template>
            </b-carousel-slide>
            <b-carousel-slide class="carouselmy">
              <template v-slot:img>
                <img
                  src="@/assets/images/t5.png"
                  class="img-fluid rounded corouselhomemyimg"
                  style="height: auto !important; max-height: 475px"
                />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>

    <div class="card usedark">
      <div class="card-body">
        <div class="letarea">
          <div class="lettitle">QA Manager</div>
          <div class="letcontent">
            <div class="qacontent">
              <div class="profileitem">
                <div class="profile">
                  <img src="@/assets/images/profile_qa.png" />
                </div>
                <div class="profilecontent">
                  <div class="titleitem">
                    <div class="usertitle">Tolga Karataş</div>
                    <div class="subtitle text-basered">
                      Kalite Kontrol Müdürü
                    </div>
                  </div>
                  <div>
                    <ul class="contactinfo">
                      <li>
                        <img
                          class="icon svg-white"
                          src="@/assets/images/phone-x1.svg"
                        />
                        <a class="text-white" href="tel:+905556397889"
                          >+90 555 639 78 89</a
                        >
                      </li>
                      <li>
                        <img
                          class="icon svg-white"
                          src="@/assets/images/whatsapp-x1.svg"
                        />
                        <a
                          class="text-white"
                          href="https://api.whatsapp.com/send/?phone=905556397889&text=Merhaba%2C+Bilgi+Almak+istiyorum.&app_absent=0"
                          >+90 555 639 78 89</a
                        >
                      </li>
                      <li>
                        <img
                          class="icon svg-white"
                          src="@/assets/images/mail-x1.svg"
                        />
                        <a
                          class="text-white"
                          href="mailto:+tolga.karatas@tkabilisim.com.tr"
                          >tolga.karatas@tkabilisim.com.tr</a
                        >
                      </li>
                      <li>
                        <span
                          v-b-modal.modal-qa
                          class="badge d-block w-100 cursor-pointer bg-basered"
                          >Bilgi Almak İstiyorum</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="descriptionitem">
                <p>
                  Kalite Kontrol Ekibimiz firmamız için kalite prosedürleri,
                  standart ve spesifikasyonlar oluşturmak, Müşteri
                  gereksinimlerini belirlemek amacı ile kurulmuştur. Onarımda ki
                  cihazınızın durumunu takip etmek,onarımı yapılmış fakat
                  istediğiniz gibi olmayan durumları müşterinin taleplerine
                  uygun hale getirmek amacı ile destek sağlamaktadır.
                </p>
                <p>
                  Elektronik cihazlarını tamir etmek isteyen insanlara en iyi
                  değeri ve kullanıcı deneyimini sağlamak arızaları
                  değerlendirme ve onarma konusunda on yıldan fazla deneyime
                  sahibiz ciddi bir yoğunluğumuz olduğu için Kalite Kontrol
                  Ekibimiz çağrılarınıza yetişmekte bazen yetersiz kalabilirler
                  bu gibi durumlarda Whatsapp üzerinden mesaj atabilir yada
                  doğrudan mail atabilirsiniz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card usedark">
      <div class="card-body">
        <div class="letarea">
          <div class="letheader">
            <div class="lettitle">Bilgilendirmeler</div>
            <div class="toolbar">
              <button
                @click="setCurrentDevice(item)"
                v-for="(item, index) in devicefind.devices"
                :key="index"
                class="btn btn-sm active"
              >
                {{ item.device_name }}
              </button>
            </div>
          </div>
          <div class="letcontent table-responsive">
            <table class="table table-transparent">
              <thead>
                <tr>
                  <th>Tarih</th>
                  <th>Kategori</th>
                  <th>Not</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in _qa_items" :key="index">
                  <td>
                    <div v-if="item.complated_date!=null">{{ moment(item.complated_date).format('DD.MM.YYYY HH:mm') }}</div>
                    <div>{{ item.channel_name }}</div>
                  </td>
                  <td>{{ item.category_name }}</td>
                  <td>
                    {{ item.notes }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="devicefind.current_device != null" class="card usedark stepper">
      <div class="card-body pb-0">
        <div class="letarea">
          <div class="letheader">
            <div class="lettitle">Servis Süreci</div>
            <div class="toolbar">
              <button
                @click="setCurrentDevice(item)"
                v-for="(item, index) in devicefind.devices"
                :key="index"
                class="btn btn-sm active"
              >
                {{ item.device_name }}
              </button>
            </div>
          </div>
          <div class="letcontent mt-3">
            <div
              class="
                steps
                d-none d-lg-flex d-md-flex
                flex-wrap flex-sm-nowrap
                justify-content-between
                padding-top-2x padding-bottom-1x
              "
            >
              <div @click="setActiveCard('1')" class="step" :class="{ 'completed':_steps[0].complated, 'current':_steps[0].current }">
                <div class="step-icon-wrap">
                  <div class="step-number">1</div>
                </div>
                <h4 class="step-title">Cihaz Alımı</h4>
              </div>
              <div @click="setActiveCard('2')" class="step" :class="{ 'completed':_steps[1].complated, 'current':_steps[1].current }">
                <div class="step-icon-wrap">
                  <div class="step-number">2</div>
                </div>
                <h4 class="step-title">Yapılacak İşlemler</h4>
              </div>
              <div @click="setActiveCard('3')" class="step" :class="{ 'completed':_steps[2].complated, 'current':_steps[2].current }">
                <div class="step-icon-wrap">
                  <div class="step-number">3</div>
                </div>
                <h4 class="step-title">Ödeme</h4>
              </div>
              <div @click="setActiveCard('4')" class="step" :class="{ 'completed':_steps[3].complated, 'current':_steps[3].current }">
                <div class="step-icon-wrap">
                  <div class="step-number">4</div>
                </div>
                <h4 class="step-title">Test ve Garanti</h4>
              </div>
              <div @click="setActiveCard('5')" class="step" :class="{ 'completed':_steps[4].complated, 'current':_steps[4].current }">
                <div class="step-icon-wrap">
                  <div class="step-number">5</div>
                </div>
                <h4 class="step-title">Teslimat</h4>
              </div>
              <div @click="setActiveCard('6')" class="step" :class="{ 'completed':_steps[5].complated, 'current':_steps[5].current }">
                <div class="step-icon-wrap">
                  <div class="step-number">6</div>
                </div>
                <h4 class="step-title">Tamamlandı</h4>
              </div>
            </div>
          </div>
          <div class="letcontent mt-2">
            <div class="serviceproccess">
              <div class="deviceinfo">
                <img
                  class="logo svg-white"
                  :src="_devicelogo"
                />
                <ul class="kv">
                  <li>
                    <span class="k">Marka</span>
                    <span class="v">{{
                      devicefind.current_device.brand_name
                    }}</span>
                  </li>
                  <li>
                    <span class="k">Model</span>
                    <span class="v">{{
                      devicefind.current_device.model_name
                    }}</span>
                  </li>
                  <li>
                    <span class="k">Seri No</span>
                    <span class="v">{{
                      devicefind.current_device.device_serial_number
                    }}</span>
                  </li>
                  <li>
                    <span class="k">Barkod No</span>
                    <span class="v">{{
                      devicefind.current_device.device_barcode
                    }}</span>
                  </li>
                  <li>
                    <span class="k">Yedek Durumu</span>
                    <span v-if="devicefind.current_device.allow_remove_files == false" class="v">Yedekler Saklanacak. Müdahale edilmesin.</span>
                    <span v-if="devicefind.current_device.allow_remove_files == true" class="v">Cihazımda bulunan yedekler silinebilir.</span>
                  </li>
                </ul>
                <div class="contackbox">
                  <div class="letarea mt-2">
                    <div class="letcontent">
                      <div class="title">KVKK Metni</div>
                    </div>
                    <div class="lettitle">Yedekler Hakkında</div>
                    <div class="text-xs small text-muted">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                      </p>
                    </div>
                  </div>
                </div>

                <div class="userpreferred">
                  <div class="form-group">
                    <label for="">İşletim Sistemi Parolası</label>
                    <input
                      v-model="devicefind.current_device.password_system"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Bios Parolası</label>
                    <input
                      v-model="devicefind.current_device.password_bios"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label for="dd1" class="checkboxitem">
                      <input
                        v-model="devicefind.current_device.allow_remove_files"
                        type="checkbox"
                        id="dd1"
                      />
                      <span
                        >Cihazımda bulunan verilerin silinmesine onay
                        veriyorum.</span>
                    </label>
                  </div>
                  <div class="form-group hidden">
                    <label for="">Notlarınız</label>
                    <textarea
                      class="form-control"
                      v-model="devicefind.current_device.customer_device_notes"
                      rows="4"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <button
                      @click="saveDeviceItem()"
                      class="btn btn-block btn-base"
                    >
                      Kaydet
                    </button>
                  </div>
                  <div class="form-group">
                    <small class="text-muted"
                      >Cihaz onarıma geçtiğinde bu alanda değişiklik
                      yapamazsınız.</small
                    >
                  </div>
                </div>
                <card-contact class="d-none d-lg-block" />
              </div>

              <div class="serviceinfo">
                <card-1 v-if="active_card == '1'" />
                <card-2 v-if="active_card == '2'" />
                <card-3 v-if="active_card == '3'" />
                <card-4 v-if="active_card == '4'" />
                <card-5 v-if="active_card == '5'" />
                <card-6 v-if="active_card == '6'" />
                <card-contact class="d-lg-none mt-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card usedark cardfiles">
      <div class="card-body">
        <div class="letarea">
          <div class="lettitle">Cihaz Görselleri</div>
          <div class="letcontent">
            <silent-box :gallery="_device_images"></silent-box>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-qa"
      hide-footer
      title="Lütfen Bilgi Almak İstediğiniz Konuyu Belirtin"
      bodyBgVariant="dark"
      headerTextVariant="dark"
      footerBgVariant="dark"
      centered
    >
      <div class="form-group">
        <label class="text-white">Mesajınız</label>
        <textarea v-model="qa.message" class="form-control dark" rows="8"></textarea>
      </div>
      <div class="form-group">
        <small>{{ devicefind.current_device.device_name }} için işlem yapmaktasınız. </small>
      </div>
      <div class="form-group">
        <button @click="sendMessageToQA()" class="btn bg-basered text-white">Gönder</button>
      </div>
    </b-modal>

    <b-modal
      id="modal-request"
      hide-footer
      title="Talep Oluştur"
      bodyBgVariant="dark"
      headerTextVariant="dark"
      footerBgVariant="dark"
      centered
    >
      <div class="form-group">
        <label class="text-white">Talebiniz</label>
        <textarea v-model="request.message" class="form-control dark" rows="6"></textarea>
      </div>
      <div class="form-group">
        <small>{{ devicefind.current_device.device_name }} için işlem yapmaktasınız. </small>
      </div>
      <div class="form-group">
        <button @click="saveDeviceCustomerRequest()" class="btn bg-basered text-white">Kaydet</button>
      </div>
    </b-modal>
    <input type="hidden" id="testing-code" :value="copy_text" />
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide, BImg } from "bootstrap-vue";
import { BCard, BCardText, BLink } from "bootstrap-vue";
import { BFormSelect } from "bootstrap-vue";
import Card1 from "./Card1.vue";
import Card2 from "./Card2.vue";
import Card3 from "./Card3.vue";
import Card4 from "./Card4.vue";
import Card5 from "./Card5.vue";
import Card6 from "./Card6.vue";
import CardContact from "./CardContact.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import VueSilentbox from "vue-silentbox";
import moment from "moment";
import api from "@/service/api";
import utils from "@/service/utils";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BCarousel,
    BCarouselSlide,
    BImg,
    BFormSelect,
    Card1,
    Card2,
    Card3,
    Card4,
    Card5,
    Card6,
    CardContact,
    VueSilentbox,
  },
  methods: {
    copy(v) {
      this.copy_text = v;
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
      } catch (err) {}
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    setCurrentDevice(device_item) {
      this.devicefind.current_device = device_item;
    },
    setActiveCard(stepno)
    {
      this.active_card = stepno;
       return;
        if( this._steps[stepno - 1].current == true  )
        {
          this.active_card = this._steps[stepno - 1].step;
        }
    },
    saveDeviceItem() {
      console.log("devicefind", this.devicefind);
      // 'device_uniq', 'password_system', 'password_bios', 'allow_remove_files', 'customer_device_notes', 'customer_requests'
      let postData = 
      {
        customer_uniq: this.devicefind.customer.customer_uniq,
        device_uniq: this.devicefind.current_device.device_uniq,
        password_system: this.devicefind.current_device.password_system,
        password_bios: this.devicefind.current_device.password_bios,
        allow_remove_files: this.devicefind.current_device.allow_remove_files ? 2:1,
        customer_device_notes:this.devicefind.current_device.customer_device_notes,
      };
      api.action("saveDeviceItem", postData, "common").then((q) => {
        if (!q.success) {
          utils.notificationToast("danger", q.message);
          return;
        }
        else
        {
             utils.notificationToast("success", q.message);
        }
      });
    },
    sendMessageToQA()
    {
      if( this.qa.message.length <= 15 )
      {
        utils.notificationToast('warning','Lütfen mesajınızı yazın.');
        return;
      }
      let postData = 
      {
        sf_uniq:this.devicefind.sf.sf_uniq,
        customer_uniq:this.devicefind.customer.customer_uniq,
        device_uniq:this.devicefind.current_device.device_uniq,
        message:this.qa.message
      }
      api.action('sendMessageToQA',postData,"common").then( (q)=>
      {
        if(!q.success)
        {
          utils.notificationToast("danger", q.message);
          return;
        }else
        {
           utils.notificationToast("success", q.message);
           this.$bvModal.hide('modal-qa');
           this.qa.message = '';
        }
      })
    },
    saveDeviceCustomerRequest()
    {
      if( this.request.message.length <= 15 )
      {
        utils.notificationToast('warning','Lütfen mesajınızı yazın.');
        return;
      }

      let postData = 
      {
        sf_uniq:this.devicefind.sf.sf_uniq,
        customer_uniq:this.devicefind.customer.customer_uniq,
        device_uniq:this.devicefind.current_device.device_uniq,
        message:this.request.message
      }

      api.action('saveDeviceCustomerRequest',postData,"common").then( (q)=>
      {
        if(!q.success)
        {
          utils.notificationToast("danger", q.message);
          return;
        }else
        {
           utils.notificationToast("success", q.message);
           this.$bvModal.hide('modal-request');
           this.qa.message = '';
           q.data.created_props = JSON.parse( q.data.created_props );
           this.devicefind.current_device.customer_device_request_message.push(q.data);
        }
      })

    },
    moment(v) {
      return moment(v);
    },
  },
  created() {
    if (this.devicefind.sf == null) {
      this.$router.push("/login");
    }
    
    let findActiveCard = this._steps.find(q=> q.current);
    this.active_card = findActiveCard.step;
    console.log('findActiveCard',findActiveCard);

  },
  computed: {
    ...mapState({
      devicefind: (state) => state.devicefind,
    }),
    _customer_name() {
      let result = "";
      let parts = this.devicefind.customer.title.split(" ");
      parts.forEach((q) => {
        result += q.substring(0, 2);
        for (let i = 0; i <= q.length - 2; i++) {
          result += "*";
        }
        result += " ";
      });
      return result;
    },
    _customer_gsm()
    {
      let result = '';
      if( this.devicefind.customer.gsm_primary!='' )
      {
        result = "5** *** "+this.devicefind.customer.gsm_primary.slice(-4)
      }
      return result;
    },
    _customer_email()
    {
      let result = '';
      if( this.devicefind.customer.email_primary !='' )
      {
        let parts = this.devicefind.customer.email_primary.split('@');
        if( parts[0] && parts[1] )
        {
          result = parts[0].substring(0,2)+"*********@"+parts[1];
        }

      }
      return result;
    },
    _device_images() {
      let result = [];

      this.devicefind.current_device.files.forEach((q) => {
        let item = {
          src: q.fullurl,
          description: "",
          alt: "",
          thumbnailWidth: "220px",
        };
        result.push(item);
      });

      return result;
    },
    _devicelogo()
    {
      //devicefind.current_device.brand_logo
      return `https://api.tkabilisim.com.tr/assets/images/device/${this.devicefind.current_device.brand_logo}`
    },
    _select_devices()
    {
      let result = [];
      this.devicefind.devices.forEach(q=>
      {
        result.push({ value:q.device_id,text:q.device_name });
      });
      return result;
    },
    _qa_items()
    {
      return this.devicefind.customer_informations.filter(q=> q.type == 0);
    },
    _steps()
    {
      let result = [];
      result.push({ step:1,current:true,complated:false });
      result.push({ step:2,current:false,complated:false });
      result.push({ step:3,current:false,complated:false });
      result.push({ step:4,current:false,complated:false });
      result.push({ step:5,current:false,complated:false });
      result.push({ step:6,current:false,complated:false });

      if(  ['pending','delivery_take_pending','delivery_take_processing','delivery_take_complated'].indexOf( this.devicefind.current_device.current_status.status ) > -1 )
      {
        result[0].step = 1;
        result[0].current = true;
        result[0].complated = false; 
      }

      else if(  ['analysis_pending','analysis_processing','analysis_complated','outservice_pending',
            'outservice_processing','outservice_complated','part_domestic_pending','part_abroad_pending'].indexOf( this.devicefind.current_device.current_status.status ) > -1 )
      {
        
        result[0].current = false;
        result[0].complated = true; 

        result[1].step = 2;
        result[1].current = true;
        result[1].complated = false; 

      }

      else if(  ['repair_pending'].indexOf( this.devicefind.current_device.current_status.status ) > -1 )
      {

        result[0].current = false;
        result[0].complated = true; 

        result[1].current = false;
        result[1].complated = true; 

        result[2].step = 3;
        result[2].current = true;
        result[2].complated = false; 

      }

      else if(  ['repair_pending','repair_processing','repair_complated',
                 'test_pending','test_processing','test_complated'].indexOf( this.devicefind.current_device.current_status.status ) > -1 )
      {

        result[0].current = false;
        result[0].complated = true; 

        result[1].current = false;
        result[1].complated = true; 

        result[2].current = false;
        result[2].complated = true; 

        result[3].step = 4;
        result[3].current = true;
        result[3].complated = false; 

      }

      else if(  ['delivery_send_pending','delivery_send_processing','delivery_send_complated'].indexOf( this.devicefind.current_device.current_status.status ) > -1 )
      {

        result[0].current = false;
        result[0].complated = true; 

        result[1].current = false;
        result[1].complated = true; 

        result[2].current = false;
        result[2].complated = true; 

        result[3].current = false;
        result[3].complated = true; 

        result[4].step = 5;
        result[4].current = true;
        result[4].complated = false; 

      }
      else
      {
        result[0].current = false;
        result[0].complated = true; 

        result[1].current = false;
        result[1].complated = true; 

        result[2].current = false;
        result[2].complated = true; 

        result[3].current = false;
        result[3].complated = true; 

        result[4].current = false;
        result[4].complated = true; 

        result[5].step = 6;
        result[5].current = true;
        result[5].complated = false; 
      }


      return result;
    }
  },
  data() {
    return {
      active_card: "1",
      bool_false: false,
      copy_text: "",
      qa:
      {
        device_id:0,
        message:''
      },
      request:
      {
        device_id:0,
        message:''
      },
      gallery: [
        {
          src: "images/image001.jpg",
          description: "Star Night Sky Ravine by Mark Basarab, from Unsplash.",
          alt: "Blue starry night photo.",
          thumbnailWidth: "220px",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
</style>

<template>
    <div>
      <div>
        <div class="lettitle text-uppercase">Ön Ekspertiz</div>
        <div>
          <table class="table table-gray mt-2">
            <tbody>
              <tr v-for="item_exp in devicefind.current_device.pre_ekspertise" :key="item_exp.definition_id">
                <td>
                  <div>{{ item_exp.definition_name }}</div>
                  <div v-if="item_exp.notes != ''" class="notitem">Not: {{ item_exp }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="mt-4">
        <div class="letheader">
          <div class="d-flex">
            <div class="lettitle text-uppercase">Talepleriniz</div>
            <span class="ml-2 btn-text text-red" v-b-modal.modal-request>
              <span class="badge bg-basered">YENİ TALEP OLUŞTUR</span>
            </span>
          </div>
        </div>

        <div>
          <table class="table table-gray">
            <tbody>
              <tr v-for="item_reqs in devicefind.current_device.customer_requests" :key="item_reqs.definition_id">
                <td>
                  <div>{{ item_reqs.definition_name }}</div>
                  <div v-if="item_reqs.notes != ''" class="notitem">Not: {{ item_reqs }}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-gray" 
            v-for="(item,index) in devicefind.current_device.customer_device_request_message" :key="index">
            <tbody>
              <tr v-if="item.message != ''" class="bg-basered">
                <td class="border-0">
                  <div class="row align-items-centerx">
                    <div class="col-sm-9">{{ item.message }}</div>
                    <div class="col-sm-3">
                      <div>
                        <span v-if="item.status == 'unread'">Okunmadı</span>
                        <span v-if="item.status == 'read'">Okundu</span>
                      </div>
                      <div>
                      {{ moment(item.created_props.created_at).format('DD.MM.YYYY HH:mm') }} tarihinde oluşturdunuz.
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
               <tr v-if="item.message_answer != null && item.message_answer_props!=null " class="bg-lightgray">
                <td>
                  <div class="row align-items-centerx">
                    <div class="col-sm-9">{{ item.message_answer }}</div>
                    <div class="col-sm-3">{{ moment(item.message_answer_props.created_at).format('DD.MM.YYYY HH:mm') }} tarihinde {{ item.message_answer_props.created_by_name }} tarafından yanıtlandı.</div>
                  </div>
                  <div></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment";
export default {

  methods:{

    moment(v) {
      return moment(v);
    },
  },
  computed:
  {
    ...mapState({
      devicefind: (state) => state.devicefind,
    })
  },
}
</script>
<template>
  <div>
    <div>
      <div class="lettitle text-uppercase">Uygulanan Testler</div>
      <div>
        <table class="table table-gray mt-2">
          <tbody>
            <tr v-for="(item_test, index_qs) in devicefind.current_device.test_definitions" :key="index_qs">
              <td>
                <div class="row">
                  <div class="col-sm-10">{{ item_test.name }}</div>
                  <div class="col-sm-2 text-right">
                    <div class="badge badge-success">Tamamlandı</div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-1">
          <div>
            <div class="lettitle text-uppercase">Test Notları</div>
            <div>{{ devicefind.current_device.test_notes }}</div>
          </div>
          <div v-if="devicefind.current_device.test_approved_props !=null">
            <div class="mt-1" >
              <div class="lettitle text-uppercase">Test Onaylanma Tarihi</div>
              <div>
                {{ moment(devicefind.current_device.test_approved_props.approved_at).format("DD.MM.YYYY HH:mm") }}
              </div>
            </div>
            <div class="mt-1">
              <div class="lettitle text-uppercase">Onaylayan Personel</div>
              <div>
                {{ devicefind.current_device.test_approved_props.approved_by_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="lettitle text-uppercase">Garanti Süreci</div>
      <div class="letcontent">
        <div v-for="(item_warrant, index_warrant) in devicefind.current_device.device_warrants" :key="index_warrant">
          <table class="table table-gray mt-2 mb-3">
            <tbody>
             <tr class="bg-basered" v-if="item_warrant.cancel_date !=null">
                <td>
                  <div class="row">
                    <div class="col-sm-3">İptal Tarihi</div>
                    <div class="col-sm-9">{{ moment(item_warrant.cancel_date).format("DD.MM.YYYY") }}</div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3">İptal Nedeni</div>
                    <div class="col-sm-9">{{ item_warrant.cancel_reason }}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="row">
                    <div class="col-sm-3">Garanti Türü</div>
                    <div class="col-sm-9">{{ item_warrant.warrant_type_text }}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="row">
                    <div class="col-sm-3">Garanti Süresi</div>
                    <div class="col-sm-9"><span class="badge badge-warning">{{ item_warrant.period_text }}</span></div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="row">
                    <div class="col-sm-3">Garanti Başlangıç Tarihi</div>
                    <div class="col-sm-9">{{ moment(item_warrant.start_date).format('DD.MM.YYYY') }}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="row">
                    <div class="col-sm-3">Garanti Bitiş Tarihi</div>
                    <div class="col-sm-9">{{ moment(item_warrant.end_date).format('DD.MM.YYYY') }}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="row">
                    <div class="col-sm-3">Notlar</div>
                    <div class="col-sm-9">{{ item_warrant.notes }}</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="lettitle text-uppercase">Garanti Koşulları</div>
      <div class="letcontent">
        <p>
          Şirketimiz, tüketici haklarını korumakta ve satış sonrası müşteri
          memnuniyetini en ön planda tutmaktadır. Onarım deneyiminiz ile ilgili
          yaşayabileceğiniz memnuniyetsizlik,servis kaynaklı her türlü sorun,
          titizlikle değerlendirilmekte ve en kısa sürede çözüme
          kavuşturulmaktadır.
        </p>
        <p>
          Ürünün garanti süresince arızalanması sonucu serviste geçen süre
          garanti süresine eklenir.
        </p>
        <p>Servis süresince veri kaybından servis sorumlu tutulamaz.</p>
        <p>
          Kullanımdan dolayı deforme olmuş veya hasar görmüş ürünlerin garantisi
          kabul edilmemektedir. (darbe,sıvı teması,yetkisiz müdahale vb
          durumlar.)
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import StatusBadge from "./StatusBadge.vue";
import moment from "moment";
export default {
  components: {
    StatusBadge,
  },
  data()
  {
    return {
      copy_text:''
    }
  },
  methods:
  {
    copy(v)
    {
        this.copy_text = v;
        let testingCodeToCopy = document.querySelector('#testing-code3')
          testingCodeToCopy.setAttribute('type', 'text')   
          testingCodeToCopy.select()

          try {
            var successful = document.execCommand('copy');
          } catch (err) 
          {
     
          }
          testingCodeToCopy.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges();
    },
    moment(v)
    {
      return moment(v);
    }
  },
  computed: {
    ...mapState({
      devicefind: (state) => state.devicefind,
    }),
  },
};
</script>
<template>
  <div>
    <div class="card usedark">
      <div class="card-body">
        <div class="letarea">
          <div class="lettitle">Onarım Süreci Raporu</div>
          <div class="letcontent"></div>
        </div>
        <iframe
          src="https://api.tkabilisim.com.tr/content/uploads/sf-74b1d362c8ce8e22f91a218899462692_633b4f040d495.pdf"
          frameborder="0"
          style="overflow: hidden; height: 100vh; width: 100%"
          height="100%"
          width="100%"
        ></iframe>

          <div class="signuteritem" style="margin-top: -6px; border-top: 5px solid #ffffff">
            <div
              style="
                background-color: #000;
                color: #fff;
                padding: 3rem;
                font-style: italic;
                font-size: 1.1rem;
                display: flex;
                flex: 1;
                flex-direction:column
              "
            >
              Sizinle çalışmak bir zevkti !<br />
              Gelecekteki onarımlarınız için bizi tercih edeceğinizi umuyoruz.
            </div>
            <div
            >
              <button v-on:click="downloadreport()" class="btn btn-block btn-base">Raporu İndir</button>
            </div>
          </div>
        
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import StatusBadge from "./StatusBadge.vue";
import moment from "moment";
import api from "@/service/api";
export default {
  components: {
    StatusBadge,
  },
  data()
  {
    return {
      btn_startdownload:false,
      copy_text:''
    }
  },
  methods:
  {
    downloadreport() {
      if (this.btn_startdownload) return;

      this.btn_startdownload = true;
      let filename = this.devicefind.sf.sf_uniq + ".pdf";
      api
        .downloadserviceform(
          this.devicefind.sf.sf_uniq,
          this.devicefind.customer.customer_uniq,
          filename
        )
        .then((q) => {
          this.btn_startdownload = false;
        });
    },
    moment(v)
    {
      return moment(v);
    }
  },
  computed: {
    ...mapState({
      devicefind: (state) => state.devicefind,
    }),
  },
};
</script>